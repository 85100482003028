import React, { useState } from 'react';
import styles from './styles/index.module.scss';
import icons from '@/Assets';
import { useMutation } from '@apollo/client';
import { session, gqls } from '@/Services';
import { message } from 'antd';

const Auth = () => {
    const [email, set_email] = useState('');
    const [password, set_password] = useState('');

    const [authentication, loading] = useMutation(gqls.users.authentication, {
        onError: () => {
            session.remove();
            message.warning('Доступ запрещен');
        },
        onCompleted: (data) => {
            session.set(data?.authentication?.token);
            return (window.location.href = '/');
        },
    });

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.contentTop}>
                    <img src={icons.logoWhite} />
                    <h2>Добро пожаловать!</h2>
                </div>

                <div className={styles.contentForm}>
                    <h1>Авторизация</h1>

                    <form
                        className={styles.form}
                        onSubmit={(e) => {
                            e.preventDefault();
                            authentication({
                                variables: {
                                    profile: {
                                        email,
                                        password,
                                        type: 'MERCHANT',
                                    },
                                },
                            });
                        }}
                    >
                        <label className={styles.label}>
                            <img
                                width={18}
                                height={18}
                                src={icons.mail}
                                alt="почта"
                            />
                            <input
                                required
                                value={email}
                                onChange={(val) => set_email(val.target.value)}
                                type="text"
                                placeholder="Email"
                            />
                        </label>
                        <label className={styles.label}>
                            <img
                                width={18}
                                height={18}
                                src={icons.lock}
                                alt="пароль"
                            />
                            <input
                                required
                                value={password}
                                onChange={(val) =>
                                    set_password(val.target.value)
                                }
                                type="password"
                                placeholder="Пароль"
                            />
                        </label>

                        <input type="submit" value="Войти" />
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Auth;
