import React, { useEffect, useState } from 'react';
import styles from './styles/index.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import {
    Input,
    Pagination,
    Dropdown,
    Menu,
    Popconfirm,
    Button,
    notification,
    Tooltip,
} from 'antd';
import { useLazyQuery, useMutation } from '@apollo/client';
import { gqls } from '@/Services';
import BarLoader from 'react-spinners/BarLoader';
import {
    ShopOutlined,
    EllipsisOutlined,
    CheckOutlined,
} from '@ant-design/icons';
import config from '@/Config';
import currencyFormatter from 'currency-formatter';

const Products = () => {
    const navigate = useNavigate();

    const [search, set_search] = useState('');
    const [page, set_page] = useState(1);

    const [getProducts, { loading, data }] = useLazyQuery(
        gqls.products.merchantProducts,
        {
            fetchPolicy: 'network-only',
            variables: {
                fields: {
                    page,
                    search,
                },
            },
        }
    );

    const [deleteProductMerchant] = useMutation(
        gqls.products.deleteProductMerchant,
        {
            onCompleted: () => {
                notification['success']({
                    message: 'Удалено',
                    description: 'Товар успешно удален',
                });
            },
            onError: () => {
                notification['error']({
                    message: 'ошибка',
                    description: 'Попробуйте чуть позже',
                });
            },
        }
    );

    useEffect(() => {
        getProducts();
    }, []);

    const menuSettings = (item: any) => {
        return (
            <Menu>
                <Menu.Item key="edit_product" onClick={() => {}}>
                    <Link to={`/products/edit/${item?._id}`}>Редактровать</Link>
                </Menu.Item>
                <Menu.Item key="remove_product" onClick={() => {}}>
                    <Popconfirm
                        title="Товар будет удален"
                        onConfirm={() =>
                            deleteProductMerchant({
                                variables: { product_id: item?._id },
                                refetchQueries: [
                                    {
                                        query: gqls.products.merchantProducts,
                                        variables: {
                                            fields: {
                                                page,
                                                search,
                                            },
                                        },
                                    },
                                ],
                            })
                        }
                        onCancel={() => {}}
                        okText="Удалить"
                        cancelText="Нет"
                        placement="bottomLeft"
                    >
                        Удалить
                    </Popconfirm>
                </Menu.Item>
            </Menu>
        );
    };

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.contentHeader}>
                    <h2>Товары</h2>
                    <Button
                        type="primary"
                        size="large"
                        onClick={() => navigate('/products/create')}
                    >
                        Добавить товар
                    </Button>
                </div>

                <div className={styles.searchBlock}>
                    <Input
                        onChange={(val) => set_search(val.target.value)}
                        type="primary"
                        size="large"
                        placeholder="Поиск по названию или артикулу товара"
                    />
                </div>

                {loading && (
                    <div className={styles.spaceContainer}>
                        <BarLoader
                            color="#586ce9"
                            height={3}
                            width="55%"
                            loading={loading}
                        />
                    </div>
                )}

                {data?.merchantProducts &&
                data?.merchantProducts?.data &&
                data?.merchantProducts?.data.length ? (
                    <div className={styles.blockProducts}>
                        {data?.merchantProducts?.data.map((item: any) => {
                            return (
                                <div
                                    key={item?._id}
                                    className={styles.productItem}
                                >
                                    {!!item?.sync_status && (
                                        <div className={styles.syncStatusBlock}>
                                            <Tooltip title="Синхронизировано">
                                                <CheckOutlined />
                                            </Tooltip>
                                        </div>
                                    )}

                                    <Dropdown
                                        trigger={['click']}
                                        className={styles.Dropdown}
                                        overlay={menuSettings(item)}
                                    >
                                        <EllipsisOutlined
                                            onClick={(e) => e.preventDefault()}
                                            rotate={90}
                                            className={
                                                styles.userItemSettingIcon
                                            }
                                        />
                                    </Dropdown>

                                    {item?.photo?.url ? (
                                        <img
                                            title={item?.title}
                                            src={`${config.public}${item?.photo?.url}`}
                                        />
                                    ) : (
                                        <img
                                            title={item?.title}
                                            src={`${config.public}${config.noImageUrl}`}
                                        />
                                    )}

                                    <>
                                        <p>
                                            {item?.title.length > 19
                                                ? item?.title.substr(0, 20) +
                                                  '...'
                                                : item?.title}
                                        </p>
                                        <small>
                                            Категория:{' '}
                                            {item?.shop_category
                                                ? item?.shop_category?.title
                                                : 'не добавлено'}
                                        </small>
                                        <h3>
                                            {currencyFormatter.format(
                                                item?.price || 0,
                                                { code: 'EUR', symbol: '' }
                                            ) + '₸'}
                                        </h3>
                                    </>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <div className={styles.spaceContainer}>
                        <ShopOutlined className={styles.icon} />
                        <p>Товаров не найдено</p>
                    </div>
                )}

                {data?.merchantProducts?.total > 20 ? (
                    <div className={styles.pagination}>
                        <Pagination
                            showSizeChanger={false}
                            current={page}
                            pageSize={20}
                            onChange={(val) => set_page(val)}
                            defaultCurrent={1}
                            total={data?.merchantProducts?.total}
                        />
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};

export default Products;
