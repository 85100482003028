import React from 'react';
import Profile from '@/Containers/Profile';
import { Loader, Layer } from '@/Components';
import Auth from '@/Containers/Auth';

import Products from '@/Containers/Products';
import EditProduct from '@/Containers/Products/helpers/edit';
import CreateProduct from '@/Containers/Products/helpers/create';

import BonusCards from '@/Containers/BonusCards';

import Analytics from '@/Containers/Analytics';
import { Navigate } from 'react-router-dom';

const routes = (loading: boolean, user: any) => [
    {
        path: '/',
        element: loading ? (
            <Loader />
        ) : user ? (
            <Layer user={user} title="Главная" />
        ) : (
            <Navigate to="/auth" />
        ),
        children: [
            {
                path: '/',
                element: <Profile merchant={user} />,
            },
            {
                path: '/products',
                element: <Products />,
            },
            {
                path: '/products/edit/:id',
                element: <EditProduct />,
            },
            {
                path: '/products/create',
                element: <CreateProduct />,
            },
            {
                path: '/analytics',
                element: <Analytics />,
            },
            {
                path: '/bonus-cards',
                element: <BonusCards />,
            },
        ],
    },
    {
        path: '/auth',
        element: loading ? (
            <Loader />
        ) : user ? (
            <Navigate to="/products" />
        ) : (
            <Layer title="Авторизация" login />
        ),
        children: [
            {
                path: '/auth',
                element: <Auth />,
            },
        ],
    },
];

export default routes;
