import { gql } from '@apollo/client';

const merchantViewsAndShows = gql`
    query ($fields: MerchantViewsAndShowsInput) {
        merchantViewsAndShows(fields: $fields) {
            views
            shows
            whatsapp
            call
            instagram
            web
            charts {
                category
                year
                value
            }
        }

        realestateOfMerchant {
            _id
            title
        }
    }
`;

export default {
    merchantViewsAndShows,
};
