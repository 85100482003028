import {
    ApolloClient,
    InMemoryCache,
    split,
    ApolloLink,
    HttpLink,
} from '@apollo/client';

import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';

import config from '@/Config';

const apolloClientSetup = () => {
    const httpLink = new HttpLink({
        uri:
            process.env.NODE_ENV === 'development'
                ? config.graphqlURI_dev
                : config.graphqlURI,
    });

    const wsLink = new WebSocketLink({
        uri:
            process.env.NODE_ENV === 'development'
                ? config.graphqlWSURI_dev
                : config.graphqlWSURI,
        options: {
            reconnect: true,
            connectionParams: {
                authToken: localStorage.getItem(config.TOKEN)
                    ? localStorage.getItem(config.TOKEN)
                    : 'none',
            },
        },
    });

    const link = split(
        ({ query }) => {
            const definition = getMainDefinition(query);
            return (
                definition.kind === 'OperationDefinition' &&
                definition.operation === 'subscription'
            );
        },
        wsLink,
        httpLink
    );

    const authLink = new ApolloLink((operation, forward) => {
        const token = localStorage.getItem(config.TOKEN);

        operation.setContext({
            headers: {
                authorization: token ? `${token}` : 'none',
            },
        });
        return forward(operation);
    });

    const client = new ApolloClient({
        link: authLink.concat(link),
        cache: new InMemoryCache({
            addTypename: false,
        }),
    });

    return client;
};

export default apolloClientSetup;
