import { gql } from '@apollo/client';

const merchantProducts = gql`
    query ($fields: MerchantProductsInput) {
        merchantProducts(fields: $fields) {
            data {
                _id
                title
                photo {
                    url
                }
                shop_category {
                    title
                    _id
                }
                price
                sync_status
            }
            total
        }
    }
`;

const formProduct = gql`
    query ($product_id: String) {
        shopCategories {
            title
            _id
            value
            isLeaf
            pId
            id
        }
        categoriesOf360 {
            title
            _id
            value
            isLeaf
            pId
            id
        }
        countries {
            _id
            title
        }
        productCriterias {
            _id
            title
            value
            type
        }
        getProductOfMerchant(product_id: $product_id) {
            _id
            title
            photo {
                url
            }
            link
            photos {
                url
                _id
            }
            category {
                title
                _id
            }
            shop_category {
                title
                _id
            }
            discount
            article_number
            instagram
            price
            discount_description
            unit {
                _id
                value
                title
            }
            photo {
                url
            }
            photos {
                url
            }
            material
            country
            height
            width
            depth
            length
            color
            parameters {
                type
                title
            }
        }
    }
`;

const updateProductMerchant = gql`
    mutation ($fields: updateProductMerchantInput) {
        updateProductMerchant(fields: $fields)
    }
`;

const addProductMerchant = gql`
    mutation ($fields: updateProductMerchantInput) {
        addProductMerchant(fields: $fields)
    }
`;

const deleteProductMerchant = gql`
    mutation ($product_id: String) {
        deleteProductMerchant(product_id: $product_id)
    }
`;

const getHistoryShop = gql`
    query {
        getHistorySyncPriceShop {
            sync_up
            next_sync_time
            history_list {
                _id
                count_products_of_shop
                products_updated
                count_products
                createdAt
                title
                description
                status
                user {
                    _id
                }
            }
        }
    }
`;

const getBonusCards = gql`
    query ($type: String) {
        getBonusCards(type: $type) {
            username
            _id
            apartment_number
            realestate
            checks {
                _id
                createdAt
                file {
                    url
                }
            }
            type_card {
                _id
                name
                shops {
                    _id
                    bonus_amount
                    expiration
                    shop {
                        title
                        _id
                        icon {
                            url
                        }
                    }
                }
            }
            number
        }
    }
`;

const saveCheckOfMerchant = gql`
    mutation ($check_id: String, $bonus_card_id: String) {
        saveCheckOfMerchant(check_id: $check_id, bonus_card_id: $bonus_card_id)
    }
`;

const deleteCheckOfMerchant = gql`
    mutation ($check_id: String) {
        deleteCheckOfMerchant(check_id: $check_id)
    }
`;

const startSyncShop = gql`
    mutation {
        startSyncShop {
            status
            message
        }
    }
`;

export default {
    merchantProducts,
    updateProductMerchant,
    addProductMerchant,
    formProduct,
    deleteProductMerchant,
    getBonusCards,
    saveCheckOfMerchant,
    deleteCheckOfMerchant,
    getHistoryShop,
    startSyncShop,
};
