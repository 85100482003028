import React, { useState } from 'react';
import { Line } from '@ant-design/plots';

const Chart = (props: any) => {
    const [color_line] = useState([
        '#5B8FF9',
        '#5AD8A6',
        '#5D7092',
        '#F6BD16',
        '#E8684A',
        '#6DC8EC',
        '#9270CA',
        '#FF9D4D',
        '#269A99',
        '#FF99C3',
    ]);

    const config = {
        data: props?.data,
        xField: 'year',
        yField: 'value',
        seriesField: 'category',
        color: color_line,
        smooth: true,
        autoFit: true,
        slider: {
            start: 0.02,
            end: 0.96,
        },
    };

    return <Line {...config} />;
};

export default Chart;
