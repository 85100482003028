import React, { useEffect, useState } from 'react';
import styles from './styles/index.module.scss';
import { Tabs, notification, Tooltip } from 'antd';
import { gqls } from '@/Services';
import { useMutation, useLazyQuery } from '@apollo/client';
import SyncShop from './helpers/sync';
import FormShop from './helpers/Forms/Shop';
import FormResetPassword from './helpers/Forms/ResetPassword';

const Profile = (props: any) => {
    let { merchant } = props;

    const [sync_info, set_sync_info] = useState();
    const [tab, set_tab] = useState('');

    const [getHistoryShopReq, getHistoryShop] = useLazyQuery(
        gqls.products.getHistoryShop,
        {
            fetchPolicy: 'network-only',
        }
    );

    useEffect(() => {
        if (tab === 'sync_shop') {
            getHistoryShopReq();
        }
    }, [tab]);

    const [startSyncShop] = useMutation(gqls.products.startSyncShop, {
        onCompleted: (message: any) => {
            set_sync_info(message?.startSyncShop);
            if (message?.startSyncShop?.status) {
                notification['success']({
                    message: 'Задача успешно запущена',
                    description:
                        'Синхронизация займет некоторое время, ожидайте',
                });
            }
        },
        onError: (err: any) => {
            notification['error']({
                message: 'Ошибка',
                description: 'Сервис временно недоступен, попробуйте позже',
            });
        },
        refetchQueries: [
            {
                query: gqls.products.getHistoryShop,
            },
        ],
    });

    const [updateMerchant] = useMutation(gqls.users.updateMerchant, {
        onCompleted: () => {
            return notification['success']({
                message: 'Успешно',
                description: 'Данные о мерчанте обновлены',
            });
        },
        onError: () => {
            notification['error']({
                message: 'ошибка',
                description: 'Попробуйте чуть позже',
            });
        },
        refetchQueries: [
            {
                query: gqls.users.getUser,
            },
        ],
    });

    const [changePassword] = useMutation(gqls.users.changePassword, {
        onCompleted: () => {
            notification['success']({
                message: 'Успешно',
                description: 'пароль успешно изменен',
            });
        },
        onError: () => {
            notification['error']({
                message: 'ошибка',
                description:
                    'Ошибка при сохранении, проверьте валидность данных',
            });
        },
    });

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <h2>Данные магазина</h2>

                <Tabs
                    defaultActiveKey="shop_information"
                    onChange={(key) => {
                        set_tab(key);
                    }}
                    items={[
                        {
                            label: 'Информация о мерчанте',
                            key: 'shop_information',
                            children: (
                                <FormShop
                                    updateMerchant={updateMerchant}
                                    merchant={merchant}
                                />
                            ),
                        },
                        {
                            label: (
                                <Tooltip
                                    title={
                                        !merchant?.shop?.xml_price_url &&
                                        'Добавьте XML price url'
                                    }
                                >
                                    Синхронизация
                                </Tooltip>
                            ),
                            key: 'sync_shop',
                            children: (
                                <SyncShop
                                    sync_info={sync_info}
                                    startSyncShop={startSyncShop}
                                    historyShop={getHistoryShop}
                                    merchant={merchant}
                                />
                            ),
                            disabled: merchant?.shop?.xml_price_url
                                ? false
                                : true,
                        },
                        {
                            label: 'Смена пароля',
                            key: 'change_pws',
                            children: (
                                <FormResetPassword
                                    changePassword={changePassword}
                                />
                            ),
                        },
                    ]}
                />
            </div>
        </div>
    );
};

export default Profile;
