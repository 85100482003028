import React from 'react';
import { Link } from 'react-router-dom';
import { LProps } from '../interfaces';
import styles from '../styles/index.module.scss';

const LinkItem = (props: LProps) => {
    return (
        <Link
            to={props.link}
            className={`${
                props.location.pathname === props.link && styles.activeLink
            }`}
        >
            <img src={props.icon} />
            {props.title}
        </Link>
    );
};

export default LinkItem;
