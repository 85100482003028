import { gql } from '@apollo/client';

const getUser = gql`
    query {
        getUser {
            _id
            email
            role {
                type
                title
            }
            shop {
                title
                instagram
                web
                email
                icon {
                    url
                }
                _id
                phone
                address
                xml_price_url
            }
            firstname
            lastname
            createdAt
        }
    }
`;

const authentication = gql`
    mutation ($profile: UserInput) {
        authentication(profile: $profile) {
            _id
            email
            token
        }
    }
`;

const changePassword = gql`
    mutation ($fields: ChangePasswordInput) {
        changePassword(fields: $fields)
    }
`;

const updateMerchant = gql`
    mutation ($fields: updateMerchantInput) {
        updateMerchant(fields: $fields)
    }
`;

export default {
    authentication,
    getUser,
    changePassword,
    updateMerchant,
};
