import React from 'react';
import { Outlet } from 'react-router-dom';
import styles from './styles/index.module.scss';
import { Layout } from 'antd';
import { Header, Sidebar } from '@/Components';

const Layer = (props: any) => {
    return (
        <Layout>
            <div className={`${props.login ? styles.authLayer : styles.layer}`}>
                {props.login ? (
                    <Outlet />
                ) : (
                    <div className={styles.wrapper}>
                        <Header user={props.user} />
                        <div className={styles.container}>
                            <Sidebar />
                            <div className={styles.content}>
                                <Outlet />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </Layout>
    );
};

export default Layer;
