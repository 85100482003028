import React, { useEffect, useState, useId } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from '../styles/index.module.scss';
import {
    Input,
    Button,
    Modal,
    Popover,
    Select,
    TreeSelect,
    Image,
    message,
    notification,
} from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import { gqls, api } from '@/Services';
import BarLoader from 'react-spinners/BarLoader';
import {
    ArrowDownOutlined,
    DeleteOutlined,
    LoadingOutlined,
    FileImageOutlined,
} from '@ant-design/icons';
import config from '@/Config';

const { TextArea } = Input;
const { Option } = Select;

const EditProduct = () => {
    const paramsQuery = useParams();
    const navigate = useNavigate();

    const { loading, data } = useQuery(gqls.products.formProduct, {
        fetchPolicy: 'no-cache',
        variables: {
            product_id: paramsQuery?.id,
        },
    });

    const [updateProductMerchant] = useMutation(
        gqls.products.updateProductMerchant
    );

    const [modal_color, set_modal_color] = useState(false);

    const [select_category, set_select_category] = useState(null);
    const [select_shop_category, set_select_shop_category] = useState(null);
    const [title, set_title] = useState('');
    const [instagram, set_instagram] = useState('');
    const [link, set_link] = useState('');
    const [price, set_price] = useState('');
    const [discount_description, set_discount_description] = useState('');
    const [discount, set_discount] = useState('');
    const [article_number, set_article_number] = useState('');

    const [height, set_height] = useState('');
    const [width, set_width] = useState('');
    const [depth, set_depth] = useState('');
    const [length, set_length] = useState('');
    const [color, set_color] = useState('');
    const [material, set_material] = useState([]);
    const [country, set_country] = useState(null);
    const [unit, set_unit] = useState(null);

    const [photos, set_photos] = useState([]);
    const [upload_photo_status, set_upload_photo_status] = useState(false);

    const [parameters, set_parameters] = useState([]);
    const [parameter_type, set_parameter_type] = useState('');
    const [parameter_title, set_parameter_title] = useState('');
    const [parameters_modal, set_parameters_modal] = useState(false);

    useEffect(() => {
        if (!loading) {
            set_unit(data?.getProductOfMerchant?.unit || null);
            set_country(data?.getProductOfMerchant?.country || null);
            set_select_category(
                data?.getProductOfMerchant?.category?._id || null
            );
            set_material(data?.getProductOfMerchant?.material || []);
            set_select_shop_category(
                data?.getProductOfMerchant?.shop_category?._id || null
            );
            set_title(data?.getProductOfMerchant?.title || '');
            set_instagram(data?.getProductOfMerchant?.instagram || '');
            set_link(data?.getProductOfMerchant?.link || '');
            set_price(data?.getProductOfMerchant?.price || 0);
            set_discount_description(
                data?.getProductOfMerchant?.discount_description || ''
            );
            set_discount(data?.getProductOfMerchant?.discount || 0);
            set_article_number(
                data?.getProductOfMerchant?.article_number || ''
            );
            set_height(data?.getProductOfMerchant?.height || 0);
            set_width(data?.getProductOfMerchant?.width || 0);
            set_depth(data?.getProductOfMerchant?.depth || 0);
            set_length(data?.getProductOfMerchant?.length || 0);
            set_color(data?.getProductOfMerchant?.color || '');
            set_parameters(data?.getProductOfMerchant?.parameters || []);
            set_photos(data?.getProductOfMerchant?.photos || []);
        }
    }, [loading]);

    const addParametr = () => {
        clearStateParameterModal();

        let paramData: any = {
            type: parameter_type,
            title: parameter_title,
        };

        set_parameters(parameters.concat(paramData));
    };

    const clearStateParameterModal = () => {
        set_parameter_type('');
        set_parameter_title('');
        set_parameters_modal(false);
    };

    const editProduct = () => {
        let fields: any = {};

        if (!select_shop_category) {
            return message.error('Выберите категорию товара');
        }

        if (!select_category) {
            return message.error('Выберите категорию для 360');
        }

        if (!title) {
            return message.error('Отсутствует наименование товара');
        }

        if (!photos) {
            return message.error('Загрузите несколько картинок товара');
        }

        let updatePhotos: any = photos.map((photo: any) => {
            return photo?._id;
        });

        fields.product_id = paramsQuery?.id || '';
        fields.title = title;
        fields.link = link;
        fields.category = select_category;
        fields.shop_category = select_shop_category;
        fields.price = Number(price || 0);
        fields.discount_description = discount_description;
        fields.discount = Number(discount || 0);
        fields.article_number = article_number;
        fields.instagram = instagram;
        fields.photos = updatePhotos;
        fields.photo = updatePhotos[0];
        fields.unit = unit;
        fields.material = material;
        fields.country = country;
        fields.height = height || 0;
        fields.width = width || 0;
        fields.depth = depth || 0;
        fields.length = length || 0;
        fields.color = color;
        fields.parameters = parameters;

        updateProductMerchant({
            variables: {
                fields,
            },
            onCompleted: () => {
                notification['success']({
                    message: 'Обновлено',
                    description: 'Товар успешно обновлен',
                });
                return navigate('/products');
            },
            onError: () => {
                return message.error(
                    'Сорвис пока не доступен, попробуйте позже'
                );
            },
        });
    };

    const selectColor = (clr: any) => {
        set_modal_color(false);
        set_color(clr);
    };

    const uploadFile = async (file: any) => {
        set_upload_photo_status(true);
        let data = await api.files.upload(
            file.target.files[0],
            file.target.files[0].type == 'image/webp' ? 'DOCUMENT' : 'IMAGE'
        );
        set_upload_photo_status(false);

        let newPhoto: any = {
            url: data?.url,
            _id: data?.id,
        };

        set_photos(photos.concat(newPhoto));
    };

    const mainPhoto = (img: any) => {
        let photosFilter = photos.filter(
            (photo: any) => photo?._id != img?._id
        );
        let updatePhotos: any = [img, ...photosFilter];

        set_photos(updatePhotos);
    };

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <h2>
                    Редактирование товара{' | '}
                    <small>{data?.getProductOfMerchant?.title}</small>
                </h2>

                {loading && (
                    <div className={styles.spaceContainer}>
                        <BarLoader
                            color="#586ce9"
                            height={3}
                            width="55%"
                            loading={loading}
                        />
                    </div>
                )}

                {!loading && (
                    <div className={styles.mainBlock}>
                        <Modal
                            width={'55%'}
                            title="Форма добавления характеристики"
                            visible={parameters_modal}
                            onOk={addParametr}
                            onCancel={clearStateParameterModal}
                        >
                            <Input
                                value={parameter_type}
                                onChange={(value) =>
                                    set_parameter_type(value.target.value)
                                }
                                placeholder="Тип характеристики"
                            />
                            <Input
                                value={parameter_title}
                                onChange={(value) =>
                                    set_parameter_title(value.target.value)
                                }
                                placeholder="Название характеристики"
                            />
                        </Modal>

                        <div className={styles.formBlock}>
                            <Popover content="Выберите категорию" title="">
                                <section className={styles.formSection}>
                                    <TreeSelect
                                        virtual={false}
                                        size="large"
                                        treeDataSimpleMode
                                        style={{
                                            width: '100%',
                                        }}
                                        value={select_shop_category}
                                        dropdownStyle={{
                                            maxHeight: 400,
                                            overflow: 'auto',
                                        }}
                                        placeholder="Выберите категорию"
                                        onChange={(value) =>
                                            set_select_shop_category(value)
                                        }
                                        treeData={
                                            data?.shopCategories &&
                                            data?.shopCategories.length
                                                ? data?.shopCategories
                                                : []
                                        }
                                    />
                                </section>
                            </Popover>

                            <Popover
                                content="Выберите категорию для 360"
                                title=""
                            >
                                <section className={styles.formSection}>
                                    <TreeSelect
                                        virtual={false}
                                        size="large"
                                        treeDataSimpleMode
                                        style={{
                                            width: '100%',
                                        }}
                                        value={select_category}
                                        dropdownStyle={{
                                            maxHeight: 400,
                                            overflow: 'auto',
                                        }}
                                        placeholder="Выберите категорию для 360"
                                        onChange={(value) =>
                                            set_select_category(value)
                                        }
                                        treeData={
                                            data?.categoriesOf360 &&
                                            data?.categoriesOf360.length
                                                ? data?.categoriesOf360
                                                : []
                                        }
                                    />
                                </section>
                            </Popover>

                            <Popover content="Наименование товара" title="">
                                <section className={styles.formSection}>
                                    <Input
                                        size="large"
                                        value={title}
                                        onChange={(value) =>
                                            set_title(value.target.value)
                                        }
                                        placeholder="Наименование товара"
                                    />
                                </section>
                            </Popover>

                            <Popover content="Instagram" title="">
                                <section className={styles.formSection}>
                                    <Input
                                        size="large"
                                        value={instagram}
                                        onChange={(value) => {
                                            set_instagram(value.target.value);
                                            set_link('');
                                        }}
                                        placeholder="Instagram"
                                    />
                                </section>
                            </Popover>

                            <Popover
                                content="Ссылка на товар (магазин)"
                                title=""
                            >
                                <section className={styles.formSection}>
                                    <Input
                                        size="large"
                                        value={link}
                                        onChange={(value) => {
                                            set_link(value.target.value);
                                            set_instagram('');
                                        }}
                                        placeholder="Ссылка на товар (магазин)"
                                    />
                                </section>
                            </Popover>

                            <Popover content="Цена (необязательно)" title="">
                                <section className={styles.formSection}>
                                    <Input
                                        size="large"
                                        value={price}
                                        onChange={(value) => {
                                            set_price(value.target.value);
                                        }}
                                        placeholder="Цена (необязательно)"
                                    />
                                </section>
                            </Popover>

                            <Popover content="Описание" title="">
                                <section className={styles.formSection}>
                                    <TextArea
                                        size="large"
                                        value={discount_description}
                                        onChange={(value) => {
                                            set_discount_description(
                                                value.target.value
                                            );
                                        }}
                                        rows={3}
                                        placeholder="Описание"
                                    />
                                </section>
                            </Popover>

                            <Popover
                                content="Скидка (укажите скидку в процентах)"
                                title=""
                            >
                                <section className={styles.formSection}>
                                    <Input
                                        size="large"
                                        value={discount}
                                        onChange={(value) => {
                                            set_discount(value.target.value);
                                        }}
                                        placeholder="Скидка (укажите скидку в процентах)"
                                    />
                                </section>
                            </Popover>

                            <Popover content="Артикул" title="">
                                <section className={styles.formSection}>
                                    <Input
                                        size="large"
                                        value={article_number}
                                        onChange={(value) => {
                                            set_article_number(
                                                value.target.value
                                            );
                                        }}
                                        placeholder="Артикул"
                                    />
                                </section>
                            </Popover>

                            <section className={styles.formSection}>
                                <p>Размер</p>
                                <div className={styles.formSectionRows}>
                                    <Popover content="Высота" title="">
                                        <Input
                                            size="large"
                                            value={height}
                                            onChange={(value) => {
                                                set_height(value.target.value);
                                            }}
                                            placeholder="Высота"
                                            className={
                                                styles.formSectionRowInput
                                            }
                                        />
                                    </Popover>
                                    <Popover content="Ширина" title="">
                                        <Input
                                            size="large"
                                            value={width}
                                            onChange={(value) => {
                                                set_width(value.target.value);
                                            }}
                                            placeholder="Ширина"
                                            className={
                                                styles.formSectionRowInput
                                            }
                                        />
                                    </Popover>
                                    <Popover content="Глубина" title="">
                                        <Input
                                            size="large"
                                            value={depth}
                                            onChange={(value) => {
                                                set_depth(value.target.value);
                                            }}
                                            placeholder="Глубина"
                                            className={
                                                styles.formSectionRowInput
                                            }
                                        />
                                    </Popover>
                                    <Popover content="Длина" title="">
                                        <Input
                                            size="large"
                                            value={length}
                                            onChange={(value) => {
                                                set_length(value.target.value);
                                            }}
                                            placeholder="Длина"
                                            className={
                                                styles.formSectionRowInput
                                            }
                                        />
                                    </Popover>
                                </div>
                            </section>

                            <Popover content="Выберите материал" title="">
                                <section className={styles.formSection}>
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        value={material}
                                        onChange={(value) =>
                                            set_material(value)
                                        }
                                        className={styles.formSectionSelect}
                                        filterOption={(input, option: any) =>
                                            option.children.includes(input)
                                        }
                                        showSearch
                                        size="large"
                                        placeholder="Выберите материал"
                                    >
                                        {data?.productCriterias &&
                                        data?.productCriterias.length &&
                                        data?.productCriterias.filter(
                                            (item: any) =>
                                                item?.type ===
                                                'PRODUCT_CRITERIA'
                                        ).length ? (
                                            data?.productCriterias
                                                .filter(
                                                    (item: any) =>
                                                        item?.type ===
                                                        'PRODUCT_CRITERIA'
                                                )
                                                .map((item: any) => (
                                                    <Option
                                                        key={item?._id}
                                                        value={item?._id}
                                                    >
                                                        {item?.value}
                                                    </Option>
                                                ))
                                        ) : (
                                            <Option>
                                                Материалов не найдено
                                            </Option>
                                        )}
                                    </Select>
                                </section>
                            </Popover>

                            <Popover
                                content="Выберите еденицу измерения"
                                title=""
                            >
                                <section className={styles.formSection}>
                                    <Select
                                        allowClear
                                        value={unit}
                                        onChange={(value) => set_unit(value)}
                                        className={styles.formSectionSelect}
                                        filterOption={(input, option: any) =>
                                            option.children.includes(input)
                                        }
                                        showSearch
                                        size="large"
                                        placeholder="Выберите еденицу измерения"
                                    >
                                        {data?.productCriterias &&
                                        data?.productCriterias.length &&
                                        data?.productCriterias.filter(
                                            (item: any) =>
                                                item?.type === 'PRODUCT_UNIT'
                                        ).length ? (
                                            data?.productCriterias
                                                .filter(
                                                    (item: any) =>
                                                        item?.type ===
                                                        'PRODUCT_UNIT'
                                                )
                                                .map((item: any) => (
                                                    <Option
                                                        key={item?._id}
                                                        value={item?._id}
                                                    >
                                                        {item?.value}
                                                    </Option>
                                                ))
                                        ) : (
                                            <Option>
                                                Информации не найдено
                                            </Option>
                                        )}
                                    </Select>
                                </section>
                            </Popover>

                            <Popover content="Страна производитель" title="">
                                <section className={styles.formSection}>
                                    <Select
                                        allowClear
                                        value={country}
                                        onChange={(value) => set_country(value)}
                                        className={styles.formSectionSelect}
                                        filterOption={(input, option: any) =>
                                            option.children.includes(input)
                                        }
                                        showSearch
                                        size="large"
                                        placeholder="Страна производитель"
                                    >
                                        {data?.countries &&
                                        data?.countries.length ? (
                                            data?.countries.map((item: any) => (
                                                <Option
                                                    key={item?._id}
                                                    value={item?._id}
                                                >
                                                    {item?.title}
                                                </Option>
                                            ))
                                        ) : (
                                            <Option>Стран не найдено</Option>
                                        )}
                                    </Select>
                                </section>
                            </Popover>

                            <Popover
                                content="Дополнительные характеристики товара"
                                title=""
                            >
                                <section
                                    className={`${styles.formSection} ${styles.formSectionOther}`}
                                >
                                    <p>Дополнительные характеристики товара</p>
                                    <Button
                                        onClick={() =>
                                            set_parameters_modal(true)
                                        }
                                        type="primary"
                                    >
                                        Добавить характеристику
                                    </Button>

                                    <div>
                                        {parameters && parameters.length ? (
                                            parameters.map((item: any) => {
                                                return (
                                                    <div
                                                        className={
                                                            styles.paramentItem
                                                        }
                                                        key={String(
                                                            Math.random() *
                                                                10000
                                                        )}
                                                    >
                                                        <b>{item?.type}</b>
                                                        <h6>{item?.title}</h6>
                                                        <DeleteOutlined
                                                            onClick={() => {
                                                                set_parameters(
                                                                    parameters.filter(
                                                                        (
                                                                            param: any
                                                                        ) =>
                                                                            param?.type !=
                                                                            item?.type
                                                                    )
                                                                );
                                                            }}
                                                            className={
                                                                styles.paramentItemDelete
                                                            }
                                                        />
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <h5>
                                                Нет дополнительной информации
                                            </h5>
                                        )}
                                    </div>
                                </section>
                            </Popover>

                            <Popover
                                content="Цвет"
                                title=""
                                placement="topLeft"
                            >
                                <section className={styles.formSection}>
                                    <p>Цвет</p>
                                    <div className={styles.formSectionRows}>
                                        <div
                                            className={styles.button}
                                            onClick={() =>
                                                set_modal_color(!modal_color)
                                            }
                                            style={{
                                                background:
                                                    color || 'rgb(232,234,237)',
                                            }}
                                        >
                                            <div
                                                className={styles.buttonContent}
                                                style={{
                                                    padding: 0,
                                                }}
                                            >
                                                <span>
                                                    {color
                                                        ? 'Изменить цвет'
                                                        : 'Выбрать цвет'}
                                                </span>
                                                <ArrowDownOutlined />
                                            </div>

                                            <div
                                                className={`${
                                                    styles.colorsBlock
                                                } ${
                                                    modal_color
                                                        ? styles.colorsBlockOpened
                                                        : {}
                                                }`}
                                            >
                                                <div
                                                    onClick={() =>
                                                        selectColor(
                                                            'rgb(255, 255, 255)'
                                                        )
                                                    }
                                                    className={styles.color1}
                                                ></div>
                                                <div
                                                    onClick={() =>
                                                        selectColor(
                                                            'rgb(233, 214, 190)'
                                                        )
                                                    }
                                                    className={styles.color2}
                                                ></div>
                                                <div
                                                    onClick={() =>
                                                        selectColor(
                                                            'rgb(254, 203, 218)'
                                                        )
                                                    }
                                                    className={styles.color3}
                                                ></div>
                                                <div
                                                    onClick={() =>
                                                        selectColor(
                                                            'rgb(102, 197, 253)'
                                                        )
                                                    }
                                                    className={styles.color4}
                                                ></div>

                                                <div
                                                    onClick={() =>
                                                        selectColor(
                                                            'rgb(128, 128, 128)'
                                                        )
                                                    }
                                                    className={styles.color5}
                                                ></div>
                                                <div
                                                    onClick={() =>
                                                        selectColor(
                                                            'rgb(254, 211, 48)'
                                                        )
                                                    }
                                                    className={styles.color6}
                                                ></div>
                                                <div
                                                    onClick={() =>
                                                        selectColor(
                                                            'rgb(194, 24, 168)'
                                                        )
                                                    }
                                                    className={styles.color7}
                                                ></div>
                                                <div
                                                    onClick={() =>
                                                        selectColor(
                                                            'rgb(60, 212, 198)'
                                                        )
                                                    }
                                                    className={styles.color8}
                                                ></div>

                                                <div
                                                    onClick={() =>
                                                        selectColor(
                                                            'rgb(0, 0, 0)'
                                                        )
                                                    }
                                                    className={styles.color9}
                                                ></div>
                                                <div
                                                    onClick={() =>
                                                        selectColor(
                                                            'rgb(253, 144, 38)'
                                                        )
                                                    }
                                                    className={styles.color10}
                                                ></div>
                                                <div
                                                    onClick={() =>
                                                        selectColor(
                                                            'rgb(90, 19, 154)'
                                                        )
                                                    }
                                                    className={styles.color11}
                                                ></div>
                                                <div
                                                    onClick={() =>
                                                        selectColor(
                                                            'rgb(125, 229, 45)'
                                                        )
                                                    }
                                                    className={styles.color12}
                                                ></div>

                                                <div
                                                    onClick={() =>
                                                        selectColor(
                                                            'rgb(119, 60, 11)'
                                                        )
                                                    }
                                                    className={styles.color13}
                                                ></div>
                                                <div
                                                    onClick={() =>
                                                        selectColor(
                                                            'rgb(190, 7, 18)'
                                                        )
                                                    }
                                                    className={styles.color14}
                                                ></div>
                                                <div
                                                    onClick={() =>
                                                        selectColor(
                                                            'rgb(42, 86, 215)'
                                                        )
                                                    }
                                                    className={styles.color15}
                                                ></div>
                                                <div
                                                    onClick={() =>
                                                        selectColor(
                                                            'rgb(53, 101, 13)'
                                                        )
                                                    }
                                                    className={styles.color16}
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </Popover>

                            <Popover content="Фотографии товара" title="">
                                <section
                                    className={`${styles.formSection} ${styles.formSectionBordered}`}
                                >
                                    <p>Картинки товара</p>
                                    <Button type="primary">
                                        {upload_photo_status ? (
                                            <LoadingOutlined />
                                        ) : (
                                            <>
                                                Загрузить картинку
                                                <input
                                                    type="file"
                                                    onChange={uploadFile}
                                                />
                                            </>
                                        )}
                                    </Button>
                                    <div
                                        className={styles.formSectionUploadFile}
                                    >
                                        {photos && photos.length ? (
                                            photos.map((photo: any) => {
                                                return (
                                                    <div
                                                        className={
                                                            styles.imageProduct
                                                        }
                                                        key={photo?._id}
                                                    >
                                                        <Image
                                                            src={`${config.public}${photo?.url}`}
                                                        />
                                                        <div
                                                            className={
                                                                styles.controllImage
                                                            }
                                                        >
                                                            <Popover
                                                                content="Сделать главной"
                                                                title=""
                                                            >
                                                                <span>
                                                                    <FileImageOutlined
                                                                        onClick={() =>
                                                                            mainPhoto(
                                                                                photo
                                                                            )
                                                                        }
                                                                    />
                                                                </span>
                                                            </Popover>
                                                            <Popover
                                                                content="Удалить"
                                                                title=""
                                                            >
                                                                <span>
                                                                    <DeleteOutlined
                                                                        onClick={() => {
                                                                            set_photos(
                                                                                photos.filter(
                                                                                    (
                                                                                        item: any
                                                                                    ) =>
                                                                                        item?._id !=
                                                                                        photo?._id
                                                                                )
                                                                            );
                                                                        }}
                                                                    />
                                                                </span>
                                                            </Popover>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <h3>Картинок не найдено</h3>
                                        )}
                                    </div>
                                </section>
                            </Popover>
                        </div>

                        <Button onClick={editProduct} type="primary">
                            Редактировать
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default EditProduct;
