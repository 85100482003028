import React from 'react';
import styles from './styles/index.module.scss';
import { LoadingOutlined } from '@ant-design/icons';

const LoaderComp = (props: any) => {
    const { width, height } = props;

    return (
        <div
            className={styles.loader}
            style={{
                position: width || height ? 'relative' : 'fixed',
                width: width || '100vw',
                height: height || '100vw',
            }}
        >
            <LoadingOutlined className={styles.loaderIcon} />
        </div>
    );
};

export default LoaderComp;
