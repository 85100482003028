import React, { useEffect, useState } from 'react';
import styles from './styles/index.module.scss';
import { Select, DatePicker } from 'antd';

import { useLazyQuery } from '@apollo/client';
import { gqls } from '@/Services';
import BarLoader from 'react-spinners/BarLoader';
import Chart from './helpers/Chart';

const { Option } = Select;
const { RangePicker } = DatePicker;

const Analytics = () => {
    const [realestate, set_realestate] = useState([]);
    const [date, set_date] = useState([]);

    const [merchantViewsAndShows, { loading, data }] = useLazyQuery(
        gqls.analytics.merchantViewsAndShows,
        {
            fetchPolicy: 'network-only',
            variables: {
                fields: {
                    realestate,
                    date,
                },
            },
        }
    );

    useEffect(() => {
        merchantViewsAndShows();
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.contentHeader}>
                    <h2>Аналитика</h2>

                    {loading && (
                        <div className={styles.spaceContainer}>
                            <BarLoader
                                color="#586ce9"
                                height={3}
                                width="100%"
                                loading={loading}
                            />
                        </div>
                    )}
                </div>

                <div className={styles.filter}>
                    <Select
                        mode="multiple"
                        allowClear
                        value={realestate}
                        onChange={(value) => set_realestate(value)}
                        className={styles.sectionSelect}
                        filterOption={(input, option: any) =>
                            option.children.includes(input)
                        }
                        showSearch
                        size="large"
                        placeholder="Выберите жилой комплекс"
                    >
                        {data?.realestateOfMerchant &&
                        data?.realestateOfMerchant.length ? (
                            data?.realestateOfMerchant.map((item: any) => (
                                <Option key={item?._id} value={item?._id}>
                                    {item?.title}
                                </Option>
                            ))
                        ) : (
                            <></>
                        )}
                    </Select>
                    <RangePicker
                        onChange={(_, date: any) => {
                            set_date(date);
                        }}
                    />
                </div>

                <div className={styles.blockStats}>
                    <div className={styles.blockStatsItem}>
                        <h5>
                            <small>Показы:</small>{' '}
                            {data?.merchantViewsAndShows?.shows || 0}
                        </h5>
                    </div>
                    <div className={styles.blockStatsItem}>
                        <h5>
                            <small>Просмотры:</small>{' '}
                            {data?.merchantViewsAndShows?.views || 0}
                        </h5>
                    </div>
                    <div className={styles.blockStatsItem}>
                        <h5>
                            <small>Whatsapp:</small>{' '}
                            {data?.merchantViewsAndShows?.whatsapp || 0}
                        </h5>
                    </div>
                    <div className={styles.blockStatsItem}>
                        <h5>
                            <small>Web:</small>{' '}
                            {data?.merchantViewsAndShows?.web || 0}
                        </h5>
                    </div>
                    <div className={styles.blockStatsItem}>
                        <h5>
                            <small>Instagram:</small>{' '}
                            {data?.merchantViewsAndShows?.instagram || 0}
                        </h5>
                    </div>
                    <div className={styles.blockStatsItem}>
                        <h5>
                            <small>Звонки:</small>{' '}
                            {data?.merchantViewsAndShows?.call || 0}
                        </h5>
                    </div>
                </div>

                <div className={styles.chart}>
                    {!loading && (
                        <Chart
                            data={data?.merchantViewsAndShows?.charts || []}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Analytics;
