import React, { useEffect, useState } from 'react';
import styles from './styles/index.module.scss';
import { Tabs } from 'antd';
import { useLazyQuery, useMutation } from '@apollo/client';
import { gqls } from '@/Services';
import BarLoader from 'react-spinners/BarLoader';
import TableCards from './helpers/tableCards';

const { TabPane } = Tabs;

const BonusCards = () => {
    const [type, set_type] = useState('active_cards');

    const [saveCheckOfMerchant] = useMutation(
        gqls.products.saveCheckOfMerchant,
        {
            refetchQueries: [
                {
                    query: gqls.products.getBonusCards,
                    variables: {
                        type,
                    },
                },
            ],
        }
    );

    const [deleteCheckOfMerchant] = useMutation(
        gqls.products.deleteCheckOfMerchant,
        {
            refetchQueries: [
                {
                    query: gqls.products.getBonusCards,
                    variables: {
                        type,
                    },
                },
            ],
        }
    );

    const [getBonusCards, { loading, data }] = useLazyQuery(
        gqls.products.getBonusCards,
        {
            fetchPolicy: 'network-only',
            variables: {
                type,
            },
        }
    );

    useEffect(() => {
        getBonusCards();
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.contentHeader}>
                    <h2>Бонусные карты</h2>

                    {false && (
                        <div className={styles.spaceContainer}>
                            <BarLoader
                                color="#586ce9"
                                height={3}
                                width="100%"
                                loading={false}
                            />
                        </div>
                    )}
                </div>

                <div style={{ height: 4, marginTop: 4 }}>
                    {loading && (
                        <div className={styles.spaceContainer}>
                            <BarLoader
                                color="#586ce9"
                                height={3}
                                width="100%"
                                loading={loading}
                            />
                        </div>
                    )}
                </div>

                <Tabs
                    defaultActiveKey="active_cards"
                    onChange={(key) => set_type(key)}
                >
                    <TabPane tab="Активные" key="active_cards">
                        <TableCards
                            loading={loading}
                            deleteCheckOfMerchant={deleteCheckOfMerchant}
                            saveCheckOfMerchant={saveCheckOfMerchant}
                            data={data?.getBonusCards || []}
                        />
                    </TabPane>
                    <TabPane tab="Просроченные" key="overdue_cards">
                        <TableCards
                            loading={loading}
                            deleteCheckOfMerchant={deleteCheckOfMerchant}
                            saveCheckOfMerchant={saveCheckOfMerchant}
                            data={data?.getBonusCards || []}
                        />
                    </TabPane>
                </Tabs>
            </div>
        </div>
    );
};

export default BonusCards;
