import React, { useState, useEffect } from 'react';
import styles from '../../styles/index.module.scss';
import { Loader } from '@/Components';
import { Result, Button, Modal, Table, Tag, Tooltip } from 'antd';
import { CaretRightOutlined, LoadingOutlined } from '@ant-design/icons';
import { moment } from '@/Services';

const SyncShop = (props: any) => {
    const { historyShop, startSyncShop, sync_info } = props;
    const { data, loading } = historyShop;

    const [notification_modal, set_notification_modal] = useState(false);

    useEffect(() => {
        if (sync_info && !sync_info.status) {
            set_notification_modal(true);
        } else {
            set_notification_modal(false);
        }
    }, [sync_info]);

    const startSyncEvent = () => {
        if (data?.getHistorySyncPriceShop?.sync_up) {
            return startSyncShop();
        } else {
            set_notification_modal(true);
        }
    };

    return (
        <div className={styles.syncBlock}>
            <Modal
                title="Синхронизация недоступна"
                open={notification_modal}
                footer={null}
                centered
                onCancel={() => set_notification_modal(false)}
            >
                <Result
                    status="warning"
                    title={
                        sync_info?.message
                            ? sync_info?.message
                            : data?.getHistorySyncPriceShop?.next_sync_time
                            ? moment(
                                  data?.getHistorySyncPriceShop?.next_sync_time
                              ).format(
                                  'Следующая синхронизация будет доступна DD MMMM YYYY в HH:mm'
                              )
                            : 'В данное время синхронизация недоступна'
                    }
                    extra={null}
                />
            </Modal>

            {loading ? (
                <Loader width="100%" height={400} />
            ) : data?.getHistorySyncPriceShop &&
              data?.getHistorySyncPriceShop?.history_list &&
              data?.getHistorySyncPriceShop?.history_list.length ? (
                <div className={styles.contentSync}>
                    <div className={styles.contentSyncHeader}>
                        <Button
                            size="large"
                            type="primary"
                            icon={<CaretRightOutlined />}
                            loading={false}
                            onClick={startSyncEvent}
                        >
                            Синхронизация
                        </Button>
                    </div>
                    <div className={styles.contentSyncList}>
                        <Table
                            columns={[
                                {
                                    title: 'ID задачи',
                                    dataIndex: '_id',
                                    key: '_id',
                                },
                                {
                                    title: 'Название',
                                    dataIndex: 'title',
                                    key: 'title',
                                },
                                {
                                    title: 'Примечание',
                                    key: 'description',
                                    render: (item) => (
                                        <Tooltip title={item?.description}>
                                            <p className={styles.descriptionTextSync}>{ item?.description }</p>
                                        </Tooltip>
                                    ),
                                },
                                {
                                    title: 'XML prices (кол-во товаров)',
                                    dataIndex: 'count_products',
                                    key: 'count_products',
                                    render: (count) => count || 0,
                                },
                                {
                                    title: 'Товаров в базе logovo',
                                    dataIndex: 'count_products_of_shop',
                                    key: 'count_products_of_shop',
                                    render: (count) => count || 0,
                                },
                                {
                                    title: 'Обновлено товаров',
                                    dataIndex: 'products_updated',
                                    key: 'products_updated',
                                    render: (count) => count || 0,
                                },
                                {
                                    title: 'Дата запуска',
                                    dataIndex: 'createdAt',
                                    key: 'createdAt',
                                    render: (item: any) =>
                                        moment(item).format(
                                            'DD MMMM YYYY в HH:mm'
                                        ),
                                },
                                {
                                    title: 'статус задачи',
                                    dataIndex: 'status',
                                    key: 'status',
                                    render: (item: any) =>
                                        item === 'START' ? (
                                            <Tag color="purple">
                                                <LoadingOutlined /> В процессе
                                            </Tag>
                                        ) : item === 'FINISHED' ? (
                                            <Tag color="lime">Успешно</Tag>
                                        ) : (
                                            <Tag color="red">Ошибка</Tag>
                                        ),
                                },
                            ]}
                            dataSource={
                                data?.getHistorySyncPriceShop?.history_list
                            }
                        />
                    </div>
                </div>
            ) : (
                <Result
                    status="403"
                    title="Синхронизаций не найдено"
                    subTitle="Чтобы запустить синхронизацию, нажмите синхронизация"
                    extra={
                        <Button
                            size="large"
                            type="primary"
                            icon={<CaretRightOutlined />}
                            loading={false}
                            onClick={startSyncEvent}
                        >
                            Синхронизация
                        </Button>
                    }
                />
            )}
        </div>
    );
};

export default SyncShop;
