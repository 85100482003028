import logo from './logo.svg';
import logoWhite from './logo-white.svg';
import lock from './lock.svg';
import mail from './mail.svg';
import analytic from './analytic.svg';
import logout from './logout.svg';
import products from './products.svg';
import tools from './tools.svg';
import bankCard from './bank-card.svg';

export default {
    logo,
    logoWhite,
    lock,
    mail,
    analytic,
    logout,
    products,
    tools,
    bankCard,
};
