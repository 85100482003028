import axios from '../axios';
import FormData from 'form-data';
import { notification } from 'antd';

const files = {
    upload: async (file: any, type: string) => {
        try {
            let formData = new FormData();

            formData.append('uploadType', type || 'DOCUMENT');
            formData.append('file', file);

            let { data } = await axios({
                url: '/admin/files',
                method: 'post',
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            return data;
        } catch (err: any) {
            notification['error']({
                message: 'ошибка',
                description: err?.response?.data || 'Попробуйте чуть позже',
            });
        }
    },
};

export default files;
