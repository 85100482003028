import React, { useState } from 'react';
import styles from '../styles/index.module.scss';
import currencyFormatter from 'currency-formatter';
import {
    Table,
    Popover,
    Tag,
    Button,
    Upload,
    message,
    Modal,
    List,
    Typography,
    Image,
    Popconfirm,
    Tooltip,
} from 'antd';
import config from '../../../Config';
import { moment, api } from '../../../Services';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';

const TableCards = ({
    data,
    saveCheckOfMerchant,
    deleteCheckOfMerchant,
    loading,
}: any) => {
    const [modal_visible, set_modal_visible] = useState(false);
    const [checks, set_checks] = useState([]);

    const [columns] = useState([
        {
            title: '№ карты',
            dataIndex: 'number',
            key: 'number',
            render: (number: number) => <p>{number}</p>,
        },
        {
            title: 'ФИО',
            dataIndex: 'username',
            key: 'username',
            render: (username: String) => <p>{username}</p>,
        },
        {
            title: 'Жилой комплекс',
            dataIndex: 'realestate',
            key: 'realestate',
            render: (realestate: String) => <p>{realestate}</p>,
        },
        {
            title: 'Номер квартиры',
            dataIndex: 'apartment_number',
            key: 'apartment_number',
            render: (apartment_number: String) => <p>{apartment_number}</p>,
        },
        {
            title: 'Срок действия до',
            dataIndex: 'type_card',
            key: 'type_card',
            render: (type_card: any) => (
                <b>
                    {moment(Number(type_card.shops[0]?.expiration)).format(
                        ' DD MMMM YYYY'
                    )}
                </b>
            ),
        },
        {
            title: 'Сумма',
            dataIndex: 'type_card',
            key: 'type_card',
            render: (type_card: any) => (
                <b>
                    {currencyFormatter.format(
                        type_card.shops[0]?.bonus_amount,
                        {
                            code: 'EUR',
                            symbol: '',
                        }
                    ) + '₸'}
                </b>
            ),
        },
        {
            title: 'Тип карты',
            dataIndex: 'type_card',
            key: 'type_card',
            render: (type_card: any) => {
                return (
                    <div className={styles.listShops}>
                        <p>{type_card?.name}</p>
                        <div className={styles.listShopsContent}>
                            {type_card.shops.map((shop: any, index: number) => {
                                return (
                                    <Popover
                                        key={shop?._id}
                                        placement="bottom"
                                        content={
                                            <div
                                                className={
                                                    styles.popoverContent
                                                }
                                            >
                                                <span>
                                                    Сумма бонуса:{' '}
                                                    <b>
                                                        {currencyFormatter.format(
                                                            shop?.bonus_amount,
                                                            {
                                                                code: 'EUR',
                                                                symbol: '',
                                                            }
                                                        ) + '₸'}
                                                    </b>
                                                </span>
                                                <span>
                                                    Срок действия до
                                                    <b>
                                                        {moment(
                                                            Number(
                                                                shop?.expiration
                                                            )
                                                        ).format(
                                                            ' DD MMMM YYYY'
                                                        )}
                                                    </b>
                                                </span>
                                                <span>
                                                    Осталось дней:{' '}
                                                    <b>
                                                        {moment(
                                                            Number(
                                                                shop?.expiration
                                                            )
                                                        ).diff(
                                                            moment(),
                                                            'days'
                                                        ) < 0 ? (
                                                            <Tag color="red">
                                                                0
                                                            </Tag>
                                                        ) : (
                                                            <Tag color="lime">
                                                                {moment(
                                                                    Number(
                                                                        shop?.expiration
                                                                    )
                                                                ).diff(
                                                                    moment(),
                                                                    'days'
                                                                )}
                                                            </Tag>
                                                        )}
                                                    </b>
                                                </span>
                                                <span>
                                                    Статус:{' '}
                                                    <b>
                                                        {moment(
                                                            Number(
                                                                shop?.expiration
                                                            )
                                                        ).diff(
                                                            moment(),
                                                            'days'
                                                        ) < 0 ? (
                                                            <Tag color="red">
                                                                Просрочена
                                                            </Tag>
                                                        ) : (
                                                            <Tag color="green">
                                                                Активна
                                                            </Tag>
                                                        )}
                                                    </b>
                                                </span>
                                            </div>
                                        }
                                        title={shop?.shop?.title}
                                    >
                                        <div
                                            key={shop?._id}
                                            className={styles.shopItem}
                                            style={{
                                                left: index * 16,
                                                background: `url(${config.public}${shop?.shop?.icon?.url}) center / cover no-repeat`,
                                            }}
                                        ></div>
                                    </Popover>
                                );
                            })}
                        </div>
                    </div>
                );
            },
        },
        {
            title: 'Чеки',
            key: 'hecks',
            width: 130,
            render: (item: any) => {
                return (
                    <div>
                        <Tooltip title="Посмотреть чеки">
                            <Button
                                onClick={() => {
                                    set_modal_visible(true);
                                    set_checks(item?.checks || []);
                                }}
                            >
                                Чеки
                            </Button>
                        </Tooltip>
                        <Tooltip title="Загрузить чек">
                            <Upload
                                customRequest={(file) =>
                                    uploadFile(file, item?._id)
                                }
                                onChange={onChange}
                            >
                                <Button
                                    type="primary"
                                    icon={<UploadOutlined />}
                                />
                            </Upload>
                        </Tooltip>
                    </div>
                );
            },
        },
    ]);

    const uploadFile = async (file: any, card_id: string) => {
        let data = await api.files.upload(
            file.file,
            file.file.type == 'image/webp' ? 'DOCUMENT' : 'IMAGE'
        );
        saveCheckOfMerchant({
            variables: {
                check_id: data?.id,
                bonus_card_id: card_id,
            },
        });
        file.onSuccess(data);
    };

    const onChange = (info: any) => {
        if (info.file.status === 'done') {
            set_checks([]);
            message.success(`${info.file.name} успешно загружен`);
        } else if (info.file.status === 'error') {
            message.error(
                `${info.file.name} произошла ошибка при загрузке. Попробуйте позже`
            );
        }
    };

    return (
        <>
            <Modal
                width="55%"
                title="Чеки"
                visible={modal_visible}
                footer={null}
                onCancel={() => {
                    set_checks([]);
                    set_modal_visible(false);
                }}
            >
                <List
                    loading={loading}
                    bordered={true}
                    size="small"
                    header={<div>Список чеков</div>}
                    dataSource={checks}
                    renderItem={(item: any) => (
                        <List.Item className={styles.itemCheck}>
                            <Image
                                width={60}
                                src={`${config.public}${item?.file?.url}`}
                            />
                            <div className={styles.itemCheckLeft}>
                                <Typography.Text code>
                                    {moment(item?.createdAt).format(
                                        'Загружено DD MMMM YYYY в hh:mm'
                                    )}
                                </Typography.Text>
                                <Popconfirm
                                    title="Чек будет удален"
                                    onConfirm={() => {
                                        set_checks(
                                            checks.filter(
                                                (itemCheck: any) =>
                                                    String(itemCheck?._id) !==
                                                    String(item?._id)
                                            )
                                        );

                                        deleteCheckOfMerchant({
                                            variables: {
                                                check_id: item?._id,
                                            },
                                        });
                                    }}
                                    okText="Удалить"
                                    cancelText="Нет"
                                >
                                    <Button
                                        icon={<DeleteOutlined />}
                                        size="small"
                                    />
                                </Popconfirm>
                            </div>
                        </List.Item>
                    )}
                />
            </Modal>
            <Table
                loading={loading}
                bordered
                columns={columns}
                dataSource={data || []}
            />
        </>
    );
};

export default TableCards;
