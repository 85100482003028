import React from 'react';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { ApolloProvider } from '@apollo/react-hooks';
import { BrowserRouter } from 'react-router-dom';
import Initial from '@/Containers/Initial';
import { apollo } from '@/Services';

import { ConfigProvider } from 'antd';
import ru_RU from 'antd/lib/locale/ru_RU';

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <ConfigProvider locale={ru_RU}>
            <ApolloProvider client={apollo()}>
                <BrowserRouter>
                    <Initial />
                </BrowserRouter>
            </ApolloProvider>
        </ConfigProvider>
    </React.StrictMode>
);

reportWebVitals();
