const config = {
    public: 'https://api.logovo.kz/',
    noImageUrl: 'uploads/no-image.jpg',
    app_version: '1.0.0',
    graphqlURI: 'https://api.logovo.kz/graphql',
    graphqlWSURI: 'wss://api.logovo.kz/graphql',
    graphqlURI_dev: 'http://185.102.75.211:5727/graphql',
    graphqlWSURI_dev: 'ws://185.102.75.211:5727/graphql',
    api: 'https://api.logovo.kz/v1',
    dev_api: 'http://185.102.75.211:5727/v1',
    TOKEN: 'SESSION_TOKEN',
};

export default config;
