import React, { useState } from 'react';
import { Input, Button } from 'antd';

const FormResetPassword = (props: any) => {
    const { changePassword } = props;

    const [password, set_password] = useState('');
    const [new_password, set_new_password] = useState('');
    const [new_repeat_password, set_new_repeat_password] = useState('');

    const changePasswordMerchant = () => {
        changePassword({
            variables: {
                fields: {
                    password,
                    new_password,
                    new_repeat_password,
                },
            },
        });

        set_password('');
        set_new_password('');
        set_new_repeat_password('');
    };

    return (
        <>
            <section>
                <Input
                    type="password"
                    onChange={(val) => set_password(val.target.value)}
                    placeholder="Введите пароль"
                    size="large"
                />
            </section>

            <section>
                <Input
                    type="password"
                    onChange={(val) => set_new_password(val.target.value)}
                    placeholder="Введите новый пароль"
                    size="large"
                />
            </section>

            <section>
                <Input
                    type="password"
                    onChange={(val) =>
                        set_new_repeat_password(val.target.value)
                    }
                    placeholder="повторите новый пароль"
                    size="large"
                />
            </section>
            <section>
                <Button
                    type="primary"
                    size="large"
                    onClick={changePasswordMerchant}
                >
                    изменить пароль
                </Button>
            </section>
        </>
    );
};

export default FormResetPassword;
