import config from '@/Config';

export const SessionToken = {
    get: () => {
        const token = localStorage.getItem(config.TOKEN);
        return token;
    },
    set: (token: any) => localStorage.setItem(config.TOKEN, token),
    remove: () => localStorage.removeItem(config.TOKEN),
};
