import React, { useState } from 'react';
import styles from './styles/index.module.scss';
import icons from '@/Assets';
import { Link } from 'react-router-dom';
import { HProps } from './interfaces';
import Config from '@/Config';
import { api, gqls } from '@/Services';
import { useMutation } from '@apollo/client';

const Header = (props: HProps) => {
    const [logo, set_logo] = useState(
        props?.user?.shop?.icon
            ? `${Config.public}${props.user.shop.icon.url}`
            : ''
    );
    const [updateMerchant] = useMutation(gqls.users.updateMerchant);

    const uploadLogo = async (file: any) => {
        let data = await api.files.upload(
            file.target.files[0],
            file.target.files[0].type == 'image/webp' ? 'DOCUMENT' : 'IMAGE'
        );

        updateMerchant({
            variables: {
                fields: {
                    logo: data?.id,
                },
            },
            onCompleted: () => {
                set_logo(data?.full_url);
            },
        });
    };

    return (
        <div className={styles.container}>
            <div className={styles.left}>
                <Link to="/">
                    <img src={icons.logo} alt="logo" />
                </Link>
            </div>
            <div className={styles.right}>
                <h4>Кабинет мерчанта </h4>
                <div className={styles.merchantLogo}>
                    <div>
                        <p>{props?.user?.shop?.title}</p>
                        <small>{props?.user?.email}</small>
                    </div>
                    <div
                        style={{
                            background: `${
                                props?.user?.shop?.icon
                                    ? `url(${logo}) center / contain no-repeat`
                                    : ''
                            }`,
                        }}
                        title="Изменить логотип"
                        className={styles.logo}
                    >
                        <input type="file" onChange={uploadLogo} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
