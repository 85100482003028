import React, { useState } from 'react';
import { Input, Button } from 'antd';

const FormShop = (props: any) => {
    const { updateMerchant, merchant } = props;

    const [title, set_title] = useState(merchant?.shop?.title || '');
    const [web, set_web] = useState(merchant?.shop?.web || '');
    const [phone, set_phone] = useState(merchant?.shop?.phone || '');
    const [instagram, set_instagram] = useState(
        merchant?.shop?.instagram || ''
    );
    const [email, set_email] = useState(merchant?.shop?.email || '');
    const [address, set_address] = useState(merchant?.shop?.address || '');
    const [xml_price_url, set_xml_price_url] = useState(
        merchant?.shop?.xml_price_url || ''
    );

    const editMerchant = () => {
        updateMerchant({
            variables: {
                fields: {
                    title,
                    web,
                    phone,
                    instagram,
                    email,
                    address,
                    xml_price_url,
                },
            },
        });
    };

    return (
        <>
            <section>
                <Input
                    value={title}
                    onChange={(val) => set_title(val.target.value)}
                    placeholder="Название магазина"
                    size="large"
                />
            </section>

            <section>
                <Input
                    value={web}
                    onChange={(val) => set_web(val.target.value)}
                    placeholder="Website"
                    size="large"
                />
            </section>

            <section>
                <Input
                    value={phone}
                    onChange={(val) => set_phone(val.target.value)}
                    placeholder="Телефон ( WhatsApp)"
                    size="large"
                />
            </section>

            <section>
                <Input
                    value={instagram}
                    onChange={(val) => set_instagram(val.target.value)}
                    placeholder="instagram"
                    size="large"
                />
            </section>

            <section>
                <Input
                    value={email}
                    onChange={(val) => set_email(val.target.value)}
                    placeholder="Email"
                    size="large"
                />
            </section>

            <section>
                <Input
                    value={address}
                    onChange={(val) => set_address(val.target.value)}
                    placeholder="Адрес магазина"
                    size="large"
                />
            </section>

            <section>
                <Input
                    value={xml_price_url}
                    onChange={(val) => set_xml_price_url(val.target.value)}
                    placeholder="XML price url"
                    size="large"
                />
            </section>

            <section>
                <Button type="primary" size="large" onClick={editMerchant}>
                    редактировать
                </Button>
            </section>
        </>
    );
};

export default FormShop;
