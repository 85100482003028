import React from 'react';
import styles from './styles/index.module.scss';
import icons from '@/Assets';
import { session } from '@/Services';
import LinkItem from './helpers/link';
import { useLocation } from 'react-router-dom';

const Sidebar = () => {
    const location = useLocation();

    const logout = () => {
        session.remove();
        return (window.location.href = '');
    };

    return (
        <div className={styles.container}>
            <LinkItem
                location={location}
                icon={icons.tools}
                title="Настройки магазина"
                link="/"
            />
            <LinkItem
                location={location}
                icon={icons.bankCard}
                title="Бонусные карты"
                link="/bonus-cards"
            />
            <LinkItem
                location={location}
                icon={icons.products}
                title="Товары"
                link="/products"
            />
            <LinkItem
                location={location}
                icon={icons.analytic}
                title="Аналитика 3D тура"
                link="/analytics"
            />

            <span onClick={logout}>
                <img src={icons.logout} />
                Выход
            </span>
        </div>
    );
};

export default Sidebar;
