import React from 'react';
import { useRoutes } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { gqls } from '@/Services';
import routes from './Routes';
import './styles/index.module.scss';

const Initial = () => {
    const { data, loading } = useQuery(gqls.users.getUser);

    let user =
        data?.getUser &&
        data?.getUser?.role &&
        data?.getUser?.role?.type === 'MERCHANT_SERVICE'
            ? data?.getUser
            : null;

    const routing = useRoutes(routes(loading, user));

    return routing;
};

export default Initial;
