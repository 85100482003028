import axios from 'axios';
import config from '@/Config';
import { SessionToken } from './session';

const updateAxiosConfig = (token: any) => {
    if (process.env.NODE_ENV === 'development') {
        axios.defaults.baseURL = config.dev_api;
    } else {
        axios.defaults.baseURL = config.api;
    }

    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    axios.defaults.headers.common['Access-Control-Allow-Methods'] =
        'GET,PUT,POST,DELETE';

    axios.defaults.headers.common['Authorization'] = token != null ? token : '';

    return axios;
};

let axiosWithOptions = updateAxiosConfig(SessionToken.get());

export default axiosWithOptions;
